<template>
  <div class="row">
    <b-container fluid>
      <b-card no-body>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-2">
            <b-row class="">
              <b-col cols="12">
                <div class="d-flex align-items-center mb-1">
                  <span class="text-nowrap"> Hiển thị {{filter.itemsPerPage * (filter.currentPage-1) +1}}
                    đến {{(filter.itemsPerPage * (filter.currentPage) >= totalRows)? totalRows : filter.itemsPerPage * (filter.currentPage)}} &nbsp; của {{ totalRows }} bản ghi
                  </span>
                </div>
                <vue-good-table
                  mode="remote"
                  class="my-table"
                  row-style-class="vgt-row"
                  style-class="vgt-table striped bordered"
                  :columns="columns"
                  :rows="setOfCriteriaObjects"
                  :pagination-options="paginationOptions"
                  :total-rows="totalRows"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                  :line-numbers="true"
                >
                  <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span v-if="props.column.field === 'stt'">
                      {{ props.row.originalIndex + 1 }}
                    </span>
                    <span v-else-if="props.column.field === 'status'">
                      {{ getStatusName(props.row.status) }}
                    </span>
                    <span v-else-if="props.column.field === 'evaluatedObjectGroupCode'">
                      {{ props.row.objectGroupType === OBJECT_GROUP_TYPES[0].value ? "Đánh giá lớp tín chỉ" : "Thu thập ý kiến" }}
                    </span>
                    <span
                      v-else-if="props.column.field === 'action'"
                      class="thead-group-action"
                    >
                      <b-button
                        v-if="props.row.objectGroupType === OBJECT_GROUP_TYPES[0].value"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="btn-icon"
                        size="sm"
                        title="Danh sách lớp tín chỉ"
                        @click="onListCriterias(props.row)"
                      >
                        <feather-icon icon="ListIcon" />  
                      </b-button>
                      <b-button
                        v-else
                        v-b-modal.evaluateCommonSaveModal
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="btn-icon"
                        size="sm"
                        title="Thực hiện khảo sát/đánh giá"
                        @click="onEdit(props.row)"
                      >
                        <feather-icon icon="Edit2Icon" />
                      </b-button>
                    </span>

                    <!-- Column: Common -->
                    <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                  </template>

                  <!-- pagination -->
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị {{filter.itemsPerPage * (filter.currentPage-1) +1}}
                          đến {{(filter.itemsPerPage * (filter.currentPage) >= totalRows)? totalRows : filter.itemsPerPage * (filter.currentPage)}} &nbsp; của {{ totalRows }} bản ghi
                        </span>
                      </div>
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị </span>
                        <b-form-select
                          v-model="filter.itemsPerPage"
                          :options="itemsPerPageOptions"
                          class="mx-1"
                          @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap"> bản ghi/trang</span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="totalRows"
                          :per-page="filter.itemsPerPage"
                          class="mt-1 mb-0"
                          @input="(value) => props.pageChanged({ currentPage: value })"
                        />
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
          </div>
          <SetOfCriteriaSave
            ref="setOfCriteriaSaveModalRef"
            :setOfCriteriaObject="currentMainData"
            @succeed="onSucceed"
          />
          <b-modal
            id="modal-list-criteria"
            ref="modal-list-criteria"
            centered
            size="xl"
            :hide-footer="true"
          >
            <CreditClasses :data-send="currentMainData" />
          </b-modal>
        </b-overlay>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import {
  BButton,
  BDropdown,
  BButtonGroup,
  BDropdownItem,
  BCard,
  BCol,
  BContainer,
  BFormSelect,
  BOverlay,
  BPagination,
  BRow,
  VBTooltip,
  BFormGroup, BForm,
  BFormFile,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import SetOfCriteriaSave from '@/views/set-of-criteria-objects/SetOfCriteriaSave.vue'
import vSelect from 'vue-select'
import { getUser } from '@/auth/utils'
import CreditClasses from '@/views/set-of-criteria-objects/CreditClasses.vue'
import { OBJECT_GROUP_TYPES } from '@/const/type'

export default {
  name: 'setOfCriteriaObjects',
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    CreditClasses,
    SetOfCriteriaSave,
    BButton,
    BDropdown,
    BButtonGroup,
    BDropdownItem,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
    BFormGroup,
    BForm,
    BFormFile,
    vSelect,
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      filter: {
        studentId: null,
        currentPage: 1,
        itemsPerPage: 10,
        organizationId: null,
        code: null,
        name: null,
        status: null,
        sort: null,
      },
      currentMainData: undefined,
      currentName: '',
      currentCode: '',
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 100],
      filterExport: null,
      showTableImport: false,
      tenFile: '',
      OBJECT_GROUP_TYPES,
      columns: [
        {
          label: `Mã đợt khảo sát/đánh giá`,
          field: 'code',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Tìm theo mã',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: `Tên đợt khảo sát/đánh giá`,
          field: 'name',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Tìm theo tên',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: `Loại khảo sát/đánh giá`,
          field: 'evaluatedObjectGroupCode',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
            placeholder: 'Tìm loại khảo sát/đánh giá',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Thao tác',
          field: 'action',
          sortable: false,
          tdClass: 'text-center',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      setOfCriteriaObjects: 'setOfCriteriaObject/setOfCriteriaObjects',
      totalRows: 'setOfCriteriaObject/totalRows',
      statuses: 'setOfCriteriaObject/statuses',
    }),
    statusFilterDropdownItems() {
      return this.statuses.map(status => ({ value: status.value, text: status.label }))
    },
  },
  async created() {
    this.isLoading = true
    try {
      this.updateParams({ organizationId: this.user.orgId, studentId: this.user.studentId, status: 1 })
      await this.getSetOfCriteriaObjectsFromStore()
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  mounted() {
    const grid = document.querySelector('div.vgt-responsive')
    grid.classList.add('overlay')
    grid.classList.add('custom')
  },
  methods: {
    ...mapActions({
      getSetOfCriteriaObjects: 'setOfCriteriaObject/getSetOfCriteriaObjects',
      // begin: 'setOfCriteriaObject/begin',
    }),
    onCreate() {
      this.currentMainData = undefined
    },
    onEdit(mainData) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = mainData
      this.currentMainData = rest
    },
    onListCriterias(mainData) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = mainData
      this.currentMainData = rest
      this.$refs['modal-list-criteria'].show()
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getSetOfCriteriaObjectsFromStore()
    },
    async getSetOfCriteriaObjectsFromStore() {
      this.isLoading = true
      try {
        await this.getSetOfCriteriaObjects(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: 1, itemsPerPage: param.currentPerPage })
      await this.getSetOfCriteriaObjectsFromStore()
    },
    async onSortChange(param) {
      if (param.length > 0) {
        const { field, type } = param[0]
        const selectedColumn = this.columns.findIndex(e => e.field === field)
        this.updateParams({ sort: `${selectedColumn}_${type}` })
      }
      await this.getSetOfCriteriaObjectsFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'name')) this.updateParams({ name: columnFilters.name })
      if (Object.hasOwn(columnFilters, 'code')) this.updateParams({ code: columnFilters.code })
      if (Object.hasOwn(columnFilters, 'status')) this.updateParams({ status: columnFilters.status })
      await this.getSetOfCriteriaObjectsFromStore()
    },
    getStatusName(id) {
      return this.statuses.find(status => status.value === id).label
    },
    async onSucceed() {
      await this.getSetOfCriteriaObjectsFromStore()
    },
    resetInputFile() {
      this.$refs.inputFileRef.reset()
    },
    showToast(title, icon, variant, autoHideDelay = 1000) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          autoHideDelay,
        },
      })
    },
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
